import { useState } from 'react';
import pluralise from '../../shared/utils/pluralise';
import Tooltip from '../../shared/components/Tooltip/Tooltip';
import Button from '../../shared/components/Button/Button';
import Tag, { TagColor } from '../../shared/components/Tag/Tag';

const BusinessRuleSet = ({ row, colId }) => {
	const [isShown, setIsShown] = useState(false);
	const handleClick = () => {
		setIsShown((s) => !s);
	};
	const businessRulesList = row?.[colId]?.map((businessRule) => (
		<Tag key={businessRule} label={businessRule} color={TagColor.Grey} />
	));

	return (
		<div className="flex flex-col items-stretch">
			{businessRulesList?.length < 4 ? (
				<>{businessRulesList}</>
			) : (
				<>
					{businessRulesList?.[0]}
					{businessRulesList?.[1]}
					{businessRulesList?.[2]}
					{isShown && businessRulesList?.slice(3)}
					<Button variant="unstyled" onClick={handleClick}>
						<div className="flex justify-center mt-1 text-xs">
							{!isShown ? (
								<Tooltip
									content={
										<>
											{pluralise(
												businessRulesList?.length - 3,
												'more rule',
												'more rules'
											)}
										</>
									}
									placement="right"
								>
									<span>
										<text className="underline">show more...</text>
									</span>
								</Tooltip>
							) : (
								<text className="underline">show less</text>
							)}
						</div>
					</Button>
				</>
			)}
		</div>
	);
};

export default BusinessRuleSet;
