import { useCallback, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'react-query';
import clsx from 'clsx';

import {
	CREATE_STRATEGY,
	DELETE_STRATEGY,
	GET_DEFAULT_STRATEGY,
	GET_TEST_STRATEGY,
} from '../../shared/api/strategies';

import get from '../../shared/utils/get';
import pluralise from '../../shared/utils/pluralise';
import useModal from '../../shared/hooks/useModal';
import useChannelQuery from '../../shared/hooks/useChannelQuery';

import AlgorithmButton from '../../shared/components/AlgorithmButton/AlgorithmButton';
import Title from '../../shared/components/Title/Title';
import Tooltip from '../../shared/components/Tooltip/Tooltip';
import Button from '../../shared/components/Button/Button';
import Text from '../../shared/components/Text/Text';
import IconButton from '../../shared/components/IconButton/IconButton';
import CheckmarkIcon from '../../shared/components/Icons/Checkmark';
import CrossIcon from '../../shared/components/Icons/Cross';
import Table from '../../shared/components/Table/Table';
import EditIcon from '../../shared/components/Icons/Edit';
import InfoIcon from '../../shared/components/Icons/Info';
import TriangleLeftIcon from '../../shared/components/Icons/TriangleLeft';
import Modal from '../../shared/components/Modal/Modal';
import LinearProgress from '../../shared/components/Progress/LinearProgress';

const HEADINGS = [
	{ id: 'category.name', label: 'Category' },
	{ id: 'category.category_products_count', label: 'Products' },
	{ id: 'objective_residual_value', label: 'Optimization objective' },
	{ id: 'business_rules', label: 'Business rule(s)' },
	{ id: 'end_period', label: 'End of markdown period' },
];

const TestStrategyOverview = () => {
	const { open, close } = useModal();
	const history = useHistory();

	const {
		isLoading: isCurrentStrategyLoading,
		isFetching: isCurrentStrategyFetching,
		data: currentStrategy,
	} = useChannelQuery('default-strategy', GET_DEFAULT_STRATEGY);

	const {
		isLoading: isTestStrategyLoading,
		isFetching: isTestStrategyFetching,
		data: testStrategy,
		refetch: refetchTestStrategy,
	} = useChannelQuery('test-strategy', GET_TEST_STRATEGY);

	const { isLoading: isCreateLoading, mutate: createStrategy } = useMutation(
		CREATE_STRATEGY,
		{
			onSuccess: refetchTestStrategy,
		}
	);

	const { isLoading: isDeleteLoading, mutate: deleteStrategy } = useMutation(
		DELETE_STRATEGY,
		{
			onSuccess: createStrategy,
		}
	);

	const renderCell = useCallback(
		(row, columnId) => {
			if (columnId === 'actions' || columnId === 'actions_invis') {
				return (
					<span
						className={clsx({ invisible: columnId === 'actions_invis' })}
						style={{ maxWidth: '10px', display: 'block' }}
					>
						<IconButton
							tooltip="Edit"
							icon={EditIcon}
							onClick={() =>
								history.push(
									`/scenario/strategy/${testStrategy?.id}/components/${row?.id}/edit`
								)
							}
						/>
					</span>
				);
			}

			if (columnId === 'objective_residual_value') {
				return (
					<span className="flex flex-col space-y-2">
						<span className="flex items-center">
							<Tooltip
								content={
									row?.objective_residual_value > 0 &&
									row?.objective_residual_value < 100 ? (
										<>
											Value of rest stock at end of the season as a percentage
											of the cost price. <br /> A higher residual value will
											lead to less aggressive markdowns.
										</>
									) : null
								}
							>
								<span className="flex items-center space-x-1">
									{row?.objective_residual_value === 0 && <span>Turnover</span>}
									{row?.objective_residual_value === 100 && <span>Margin</span>}
									{row?.objective_residual_value > 0 &&
										row?.objective_residual_value < 100 && (
											<>
												<span>Custom {row?.objective_residual_value}%</span>
												<InfoIcon className="inline-block text-ca-gray h-3.5" />
											</>
										)}
								</span>
							</Tooltip>
						</span>
						<span className="flex items-center">
							Shipping cost{' '}
							{row?.objective_include_shipping_cost ? (
								<CheckmarkIcon className="inline ml-1 h-2 text-ca-green" />
							) : (
								<CrossIcon className="inline ml-1 h-2 text-ca-red" />
							)}
						</span>
						<span className="flex items-center">
							Return cost{' '}
							{row?.objective_include_return_cost ? (
								<CheckmarkIcon className="inline ml-1 h-2 text-ca-green" />
							) : (
								<CrossIcon className="inline ml-1 h-2 text-ca-red" />
							)}
						</span>
					</span>
				);
			}

			if (columnId === 'business_rules') {
				if (row?.[columnId].length > 2) {
					return (
						<span className="flex items-center">
							{row?.[columnId]?.[0]?.title}, {row?.[columnId]?.[1]?.title}
							&nbsp;+&nbsp;
							<Tooltip
								content={
									<>
										{row?.[columnId]?.slice(2).map((br) => (
											<Fragment key={br?.id}>
												{br?.title}
												<br />
											</Fragment>
										))}
									</>
								}
							>
								<span className="flex items-center space-x-1">
									<span>
										{pluralise(row?.[columnId]?.length - 2, 'rule', 'rules')}
									</span>
									<InfoIcon className="inline-block text-ca-gray h-3.5" />
								</span>
							</Tooltip>
						</span>
					);
				}

				return row?.[columnId]?.map(({ title }) => title).join(', ');
			}

			return get(row, columnId);
		},
		[currentStrategy, testStrategy]
	);

	const handleReset = () => {
		close();
		deleteStrategy(testStrategy?.id);
	};

	return (
		<>
			<div className="absolute left-32 right-0 top-0">
				<LinearProgress
					visible={
						isCurrentStrategyFetching ||
						isTestStrategyFetching ||
						isDeleteLoading ||
						isCreateLoading
					}
				/>
			</div>
			<div className="py-6">
				<Title type="section">Active strategy</Title>
				<Text type="secondary" className="my-3">
					As a reference point, here is the active strategy that was used to
					generate markdown recommendations. It is not editable.
				</Text>
				<div className="mt-6">
					<Table
						loading={isCurrentStrategyLoading}
						itemsLoading={5}
						headings={[
							...HEADINGS,
							{ id: 'actions_invis', label: '', align: 'right' },
						]}
						rows={currentStrategy?.components}
						renderCell={renderCell}
					/>
				</div>
			</div>
			<div className="py-6">
				<Title type="section">Test strategy</Title>
				<div className="space-y-6 mt-6">
					<Table
						loading={
							isDeleteLoading || isCreateLoading || isTestStrategyLoading
						}
						itemsLoading={5}
						emptyState="No test strategy found. Click the reset button below to create it."
						headings={[
							...HEADINGS,
							{ id: 'actions', label: '', align: 'right' },
						]}
						rows={testStrategy?.components}
						renderCell={renderCell}
					/>
					{!isDeleteLoading && !isCreateLoading && !isTestStrategyLoading && (
						<div className="flex space-x-5">
							{window._ENV_.REACT_APP_ALGORITHM_RUN_BUTTON_ENABLED && (
								<AlgorithmButton name="test" strategyId="test" />
							)}
							<Button
								onClick={() =>
									open(
										<Modal.Root>
											<Modal.Content>
												<Modal.Title>Reset test strategy</Modal.Title>
												<div className="space-y-4">
													<Text>
														Are you sure you want to reset this strategy?
													</Text>
													<Text className="italic">
														This action is irreversible. Please proceed with
														caution.
													</Text>
												</div>
											</Modal.Content>
											<Modal.Actions>
												<div className="flex justify-between">
													<Button variant="secondary" onClick={close}>
														Cancel
													</Button>
													<Button onClick={handleReset}>Reset</Button>
												</div>
											</Modal.Actions>
										</Modal.Root>
									)
								}
								variant="secondary"
							>
								Reset test strategy
							</Button>
						</div>
					)}
					<div className="flex space-x-5 mt-0 pt-0">
						<Button
							onClick={() => history.push(`/scenario`)}
							variant="secondary"
						>
							<TriangleLeftIcon className="inline-block h-3.5" />
							&nbsp; Go back
						</Button>
					</div>
				</div>
			</div>
		</>
	);
};

export default TestStrategyOverview;
