import { Fragment, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { useForm, Controller } from 'react-hook-form';
import ReactSlider from 'react-slider';
import clsx from 'clsx';

import { GET_BUSINESS_RULES } from '../../../shared/api/business-rules';
import {
	GET_STRATEGY_COMPONENT,
	UPDATE_STRATEGY_COMPONENT,
} from '../../../shared/api/strategies';

import get from '../../../shared/utils/get';
import useChannelQuery from '../../../shared/hooks/useChannelQuery';

import Title from '../../../shared/components/Title/Title';
import Tooltip from '../../../shared/components/Tooltip/Tooltip';
import InfoIcon from '../../../shared/components/Icons/Info';
import Table from '../../../shared/components/Table/Table';
import Tag from '../../../shared/components/Tag/Tag';
import Button from '../../../shared/components/Button/Button';
import RadioGroup from '../../../shared/components/Radio/RadioGroup';
import Checkbox from '../../../shared/components/Checkbox/Checkbox';
import Text from '../../../shared/components/Text/Text';
import { SingleTrack } from '../../../shared/components/Slider/Slider';
import TextInput from '../../../shared/components/TextInput/TextInput';
import InputWithLabel from '../../../shared/components/InputWithLabel/InputWithLabel';
import LinearProgress from '../../../shared/components/Progress/LinearProgress';

const HEADINGS = [
	{ id: 'title', label: 'Name' },
	{ id: 'tag', label: 'Tag' },
	{ id: 'action_type', label: 'Type' },
	{ id: 'discount', label: 'Discount' },
];

const StrategyComponentsEdit = () => {
	const history = useHistory();
	const { strategyId, componentId } = useParams();
	const { formState, setValue, control, handleSubmit, watch } = useForm({
		defaultValues: {
			objective: 'turnover',
			objective_residual_value: 0,
			objective_include_shipping_cost: true,
			objective_include_return_cost: true,
			end_period: '',
			business_rules_ids: [],
		},
	});
	const queryClient = useQueryClient();

	const [watchObjective, watchResidualValue] = watch([
		'objective',
		'objective_residual_value',
	]);

	useEffect(() => {
		if (watchObjective === 'turnover' && watchResidualValue !== 0) {
			setValue('objective_residual_value', 0);
		} else if (watchObjective === 'margin' && watchResidualValue !== 100) {
			setValue('objective_residual_value', 100);
		} else if (
			// only reset reset value if coming from turnover or margin presets
			watchObjective === 'custom' &&
			(watchResidualValue === 0 || watchResidualValue === 100)
		) {
			setValue('objective_residual_value', 50);
		}
	}, [watchObjective]);

	useEffect(() => {
		if (watchResidualValue === 0 && watchObjective !== 'turnover') {
			setValue('objective', 'turnover');
		} else if (watchResidualValue === 100 && watchObjective !== 'margin') {
			setValue('objective', 'margin');
		} else if (
			watchResidualValue > 0 &&
			watchResidualValue < 100 &&
			watchObjective !== 'custom'
		) {
			setValue('objective', 'custom');
		}
	}, [watchResidualValue]);

	const isScenario = history.location.pathname.includes('scenario');
	const returnUrl = isScenario
		? `/scenario/strategy/test`
		: `/strategy/components`;

	const {
		isLoading: isBusinessRulesLoading,
		isFetching: isBusinessRulesFetching,
		data: businessRules,
		channel,
	} = useChannelQuery('business-rules', GET_BUSINESS_RULES);

	const { isFetching, data: component } = useChannelQuery(
		['strategy-component', strategyId, componentId],
		() => GET_STRATEGY_COMPONENT(strategyId, componentId),
		{
			onError: async ({ response }) => {
				if (response?.status === 404) {
					history.push(returnUrl);
				}
			},
			retry: false,
		}
	);

	useEffect(() => {
		if (component) {
			setValue('setting_id', component?.setting?.id);
			setValue(
				'business_rules_ids',
				component?.business_rules?.map(({ id }) => id)
			);
			setValue('end_period', component?.end_period);

			if (component?.objective_residual_value === 0) {
				setValue('objective', 'turnover');
			} else if (component?.objective_residual_value === 100) {
				setValue('objective', 'margin');
			} else {
				setValue('objective', 'custom');
			}

			setValue('objective_residual_value', component?.objective_residual_value);
			setValue(
				'objective_include_shipping_cost',
				component?.objective_include_shipping_cost
			);
			setValue(
				'objective_include_return_cost',
				component?.objective_include_return_cost
			);
		}
	}, [component]);

	const targetStrategy = isScenario ? 'test-strategy' : 'default-strategy';

	const {
		isLoading: isEditLoading,
		isSuccess: isEditSuccess,
		mutate: editComponent,
	} = useMutation(
		(payload) => UPDATE_STRATEGY_COMPONENT(strategyId, componentId, payload),
		{
			onMutate: async (payload) => {
				// Cancel any outgoing refetches (so they don't overwrite our optimistic update)
				await queryClient.cancelQueries([channel, targetStrategy]);

				// Snapshot the previous value
				const previousStrategy = queryClient.getQueryData([
					channel,
					targetStrategy,
				]);

				if (previousStrategy) {
					// Optimistically update to the new value
					queryClient.setQueryData([channel, targetStrategy], (old) => {
						return {
							...old,
							components: (old?.components || []).map((comp) => {
								if (comp.id === component.id) {
									return {
										...payload,
										business_rules: payload.business_rules_ids.map((bId) =>
											businessRules?.items.find(({ id }) => id === bId)
										),
										category: comp.category,
										category_products_count: comp.category_products_count,
										is_default: comp.is_default,
									};
								}

								return comp;
							}),
						};
					});
				}

				// Return a context object with the snapshotted value
				return { previousStrategy };
			},
			// If the mutation fails, use the context returned from onMutate to roll back
			onError: (err, payload, context) => {
				queryClient.setQueryData(
					[channel, targetStrategy],
					context.previousBusinessRules
				);
			},
			// Always refetch after error or success:
			onSettled: () => {
				queryClient.invalidateQueries([channel, targetStrategy]);
			},
		}
	);

	const onSubmit = (d) => {
		editComponent(d);
	};

	const handleAnimationEnded = () => {
		if (isEditSuccess) {
			history.push(returnUrl);
		}
	};

	return (
		<>
			<div className="absolute left-32 right-0 top-0">
				<LinearProgress
					visible={isBusinessRulesFetching || isEditLoading || isFetching}
					onAnimationEnded={handleAnimationEnded}
				/>
			</div>
			<div className="py-6">
				<Title type="section">Edit component</Title>
				{!isFetching && (
					<form className="mt-3" onSubmit={handleSubmit(onSubmit)}>
						<Text type="secondary">
							Edit this strategy component by combining markdown settings and
							business rules for this category
						</Text>
						<div className="mt-6 space-y-5">
							<InputWithLabel
								label="Category"
								htmlFor="category_id"
								labelClassName={clsx(
									'w-64 mb-2 md:mb-0',
									formState?.errors?.category_id?.message && '-mt-5'
								)}
							>
								<Text>{component?.category?.name}</Text>
							</InputWithLabel>
							{!isScenario && (
								<InputWithLabel
									label="End of markdown period"
									htmlFor="end_period"
									labelClassName={clsx(
										'w-64 mb-2 md:mb-0',
										formState?.errors?.end_period?.message && '-mt-5'
									)}
								>
									<Controller
										name="end_period"
										control={control}
										rules={{ required: 'Required field' }}
										render={({ field }) => (
											<TextInput
												id="end_period"
												type="date"
												className="w-full sm:w-64"
												value={field.value}
												onChange={(val) => field.onChange(val)}
												error={formState?.errors?.end_period?.message}
											/>
										)}
									/>
								</InputWithLabel>
							)}
							<InputWithLabel
								label="Optimization objective"
								htmlFor="objective"
								labelClassName="w-64 mb-2 md:mb-0"
							>
								<Controller
									name="objective"
									control={control}
									render={({ field }) => (
										<RadioGroup
											options={[
												{ value: 'turnover', label: 'Turnover' },
												{ value: 'margin', label: 'Gross margin' },
												{ value: 'custom', label: 'Custom' },
											]}
											value={field.value}
											onChange={field.onChange}
											className={{
												root: 'space-y-3',
											}}
										/>
									)}
								/>
							</InputWithLabel>
							<InputWithLabel
								label={
									<Tooltip
										content={
											<>
												Value of rest stock at end of the season as a percentage
												of the cost price. <br /> A higher residual value will
												lead to less aggressive markdowns.
											</>
										}
									>
										<div className="flex items-center">
											Residual value of rest stock
											<span className="inline-block ml-1.5">
												<InfoIcon className="h-3.5" />
											</span>
										</div>
									</Tooltip>
								}
								htmlFor="objective_residual_value"
								labelClassName={clsx(
									'w-64 mb-2 md:mb-0',
									formState?.errors?.objective?.message && '-mt-5'
								)}
							>
								<Controller
									name="objective_residual_value"
									control={control}
									rules={{ required: 'Required field' }}
									render={({ field }) => (
										<div className="relative flex flex-wrap justify-center w-64">
											<span className="absolute -left-1.5 transform -translate-x-full top-3.5 text-xs text-ca-gray">
												0%
											</span>
											<ReactSlider
												className="w-full mt-4 mb-8"
												min={0}
												max={100}
												value={field.value}
												renderTrack={SingleTrack}
												renderThumb={({ key, ...props }, state) => (
													<div key={key}>
														<div
															{...props}
															className="w-4 h-4 bg-white border-2 border-ca-purple rounded-full cursor-grab focus-visible:outline-none"
														/>
														{state.valueNow > 0 && state.valueNow < 100 && (
															<span
																className="top-5 text-xs text-ca-gray"
																// eslint-disable-next-line react/prop-types
																style={props.style}
															>
																{state.valueNow}%
															</span>
														)}
													</div>
												)}
												onChange={field.onChange}
											/>
											<span className="absolute -right-1.5 transform translate-x-full top-3.5 text-xs text-ca-gray">
												100%
											</span>
										</div>
									)}
								/>
							</InputWithLabel>
							<div className="ml-64 space-y-3">
								<Controller
									name="objective_include_shipping_cost"
									control={control}
									render={({ field }) => (
										<Checkbox
											checked={field.value}
											onChange={field.onChange}
											label="Include shipping cost"
										/>
									)}
								/>
								<Controller
									name="objective_include_return_cost"
									control={control}
									render={({ field }) => (
										<Checkbox
											checked={field.value}
											onChange={field.onChange}
											label="Include return cost"
										/>
									)}
								/>
							</div>
						</div>
						<div className="mt-6">
							<div className="mb-4 flex justify-between items-end">
								<Text type="secondary">
									Business rules to enable in this strategy:&nbsp;
								</Text>
								{formState?.errors?.business_rules_ids?.message && (
									<p className="text-ca-red text-sm text-right">
										{formState?.errors?.business_rules_ids?.message}
									</p>
								)}
							</div>
							<Controller
								name="business_rules_ids"
								control={control}
								render={({ field }) => (
									<Table
										headings={HEADINGS}
										rows={businessRules?.items}
										selectable
										selected={field.value}
										onSelectedChange={(val) => field.onChange(val)}
										loading={isBusinessRulesLoading}
										itemsLoading={3}
										emptyState="No business rules found..."
										renderCell={(row, columnId) => {
											if (columnId === 'tag') {
												return row?.[columnId] ? (
													<Tag label={row?.[columnId]} />
												) : null;
											}

											if (columnId === 'discount') {
												if (row?.action_type === 'Custom_fixed') {
													return `Fixed ${
														row?.custom_fixed_action?.fixed_discount * 100
													}%`;
												}

												if (row?.action_type === 'Custom_average') {
													return `Average ${
														row?.custom_average_action?.average_discount * 100
													}%`;
												}

												if (row?.action_type === 'Custom_distribution') {
													return (
														<Tooltip
															content={
																<>
																	<span className="capitalize">
																		{row?.custom_distribution_action?.limiter}
																	</span>{' '}
																	<span>
																		{row?.custom_distribution_action
																			?.distribution * 100}
																		%
																	</span>
																	{' of '}
																	<span>
																		{row?.custom_distribution_action?.type}
																	</span>
																	{' should be discounted at '}
																	<span>
																		{row?.custom_distribution_action?.discount *
																			100}
																		%
																	</span>
																</>
															}
														>
															<span className="flex items-center space-x-1">
																<span>Discount distribution</span>
																<InfoIcon className="inline-block text-ca-gray h-3.5" />
															</span>
														</Tooltip>
													);
												}

												if (row?.action_type === 'Custom_minmax') {
													const output = [];

													if (
														!Number.isNaN(
															parseFloat(
																row?.custom_minmax_action?.min_discount,
																10
															)
														)
													) {
														output.push(
															`Min. ${
																row?.custom_minmax_action?.min_discount * 100
															}%`
														);
													}

													if (
														!Number.isNaN(
															parseFloat(
																row?.custom_minmax_action?.max_discount,
																10
															)
														)
													) {
														output.push(
															`Max. ${
																row?.custom_minmax_action?.max_discount * 100
															}%`
														);
													}

													return output.join(', ');
												}

												if (row?.action_type === 'Custom_possible') {
													return (
														<Tooltip
															content={
																<>
																	{row?.custom_possible_action?.markdowns.map(
																		(m) => (
																			<Fragment key={m}>
																				{Math.round(m * 100)}%<br />
																			</Fragment>
																		)
																	)}
																</>
															}
														>
															<span className="flex items-center space-x-1">
																<span>
																	{
																		row?.custom_possible_action?.markdowns
																			.length
																	}{' '}
																	values
																</span>
																<InfoIcon className="inline-block text-ca-gray h-3.5" />
															</span>
														</Tooltip>
													);
												}
											}
											if (columnId === 'action_type') {
												if (row?.[columnId] === 'Custom_minmax')
													return 'Min/max. discount';
												if (row?.[columnId] === 'Custom_fixed')
													return 'Fixed discount';
												if (row?.[columnId] === 'Custom_possible')
													return 'Possible discounts';
												if (row?.[columnId] === 'Custom_average')
													return 'Average discount';
												if (row?.[columnId] === 'Custom_distribution')
													return 'Discount distribution';
												if (row?.[columnId] === 'Global')
													return 'Built-in rules';

												return '';
											}

											const rowValue = get(row, columnId);

											if (typeof rowValue === 'number') {
												return `${rowValue}%`;
											}

											return rowValue?.toString();
										}}
									/>
								)}
							/>
						</div>
						<div className="mt-6 space-x-5">
							<Button type="submit" disabled={isEditLoading || isEditSuccess}>
								Save
							</Button>
							<Button variant="link" onClick={() => history.push(returnUrl)}>
								Cancel
							</Button>
						</div>
					</form>
				)}
			</div>
		</>
	);
};

export default StrategyComponentsEdit;
